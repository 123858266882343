import React, { useEffect, useState } from 'react';
import {
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import Icon from 'components/Icon';
import { useGetLapSponsorshipReport } from 'components/Participants/gql/useGetLapSponsorshipReport.mutation';
import { PrintParticipants } from 'components/Participants/PrintSpectators';
import { Table } from 'components/Table';
import Text from 'components/Text';
import Export from 'pages/app/Tickets/export';
import { Column } from '../../Header/StyledHeader';

export const LapSponsorshipReport = () => {
  const [lapSponsorshipReport, setLapSponsorshipReport] = useState();

  const getLapSponsorshipReport = useGetLapSponsorshipReport();

  // Get lap sponsorship data from route data
  const location = useLocation();
  const { lapSponsorship, event } = location.state || {};

  const { id } = useParams();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getLapSponsorshipReport(id);
        setLapSponsorshipReport(response?.data?.getLapSponsorshipReport);
      } catch (error) {
        console.error(error);
      }
    };

    getData();
  }, [getLapSponsorshipReport, id]);

  const columns = [
    { key: 'lap', label: 'Lap' },
    { key: 'lapText', label: 'Sponsored By' },
    { key: 'lap_price', label: 'Lap Price' },
    { key: 'purchaser_name', label: "Purchaser's Name" },
  ];

  function renderRows(sponsorship) {
    return {
      lap: sponsorship?.name,
      lapText: sponsorship?.lap_text,
      lap_price: sponsorship?.price,
      purchaser_name: sponsorship?.purchaser,
    };
  }

  const payoutColumns = [
    { label: 'Title', key: 'title' },
    { label: 'Amount', key: 'amount' },
  ];

  return (
    <>
      <div
        style={{
          justifyContent: 'end',
          display: 'flex',
          paddingTop: 20,
          paddingRight: 40,
        }}
      >
        <Column noBorder>
          <PrintParticipants
            eventId={id}
            text="Export PDF"
            date={lapSponsorshipReport?.event?.start_date}
            disable={!lapSponsorshipReport?.data?.length}
            lapSponsorshipData={
              lapSponsorship
                ? lapSponsorship
                : {
                    id: id,
                    name: lapSponsorshipReport?.name,
                    action: 'getLapSponsorshipReport',
                  }
            }
          />

          <Export
            text="Export CSV"
            event={event}
            date={lapSponsorshipReport?.event?.start_date}
            icon={<Icon icon="Export-Icon" size={40} />}
            disabled={!lapSponsorshipReport?.data?.length}
            lapSponsorshipData={
              lapSponsorship
                ? lapSponsorship
                : {
                    id: id,
                    name: lapSponsorshipReport?.name,
                    action: 'getLapSponsorshipReport',
                  }
            }
          />
        </Column>
      </div>

      <Container>
        <ContainerHeader
          borderWidth={'1px'}
          style={{ position: 'relative', width: '97%' }}
        >
          <Text
            type="heading"
            color="#3C4144"
            inlineStyle={{ marginRight: 25, whiteSpace: 'nowrap' }}
          >
            {lapSponsorshipReport?.name?.includes('Print')
              ? lapSponsorshipReport.name.replace('Print', '')
              : lapSponsorshipReport?.name ?? 'Lap Sponsorship Report'}
          </Text>
        </ContainerHeader>
        <div style={{ padding: 20 }}>
          <Table
            items={lapSponsorshipReport?.payouts}
            columns={payoutColumns}
            renderRows={(payout) => ({
              title: `${payout.title} payout`,
              amount: payout.amount,
            })}
            customPadding="15px 4px"
          />
        </div>

        {lapSponsorshipReport?.data?.length ? (
          <div style={{ padding: 20 }}>
            <Table
              items={lapSponsorshipReport?.data}
              columns={columns}
              renderRows={renderRows}
            />
          </div>
        ) : null}
      </Container>
    </>
  );
};
