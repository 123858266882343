import moment from 'moment';
import React from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useGetLapSponsorshipReport } from 'components/Participants/gql/useGetLapSponsorshipReport.mutation';
import { useGetSpectatorTicketsReport } from 'components/Participants/gql/useGetSpectatorTicketsReport.mutation';
import { logDevError } from 'shared/alerts';
import { formatPhoneNumber } from 'shared/formatters';

const Container = styled.button`
  border-radius: 5px;
  background-color: #fa4616;
  color: #fff;
  box-sizing: border-box;
  padding: 15px;
  border: none;
  width: calc(100% - 20px);
  margin: 0 10px 0 10px;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }

  @media (min-width: 860px) {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0 10px 0 10px;
    background-color: transparent;
    cursor: pointer;
    width: auto;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 860px) {
    margin: 0;
    border: 0;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  span {
    color: #fff;
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    font-weight: 700;
    line-height: 16px;

    @media (min-width: 860px) {
      text-align: center;
      font-size: 12px;
      color: #3c4144;
      text-align: center;
      margin-top: 10px;
    }
  }

  svg {
    fill: #fff;
    margin-right: 10px;

    @media (min-width: 860px) {
      fill: #fa4616;
      margin: 0;
    }
  }
`;

function download(filename, text) {
  var element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

const exportCsv = async ({ event }, ticketReport) => {
  try {
    const response = await ticketReport(event.id);
    if (!response || response.errors) {
      toast.error('Ticket Holder List can not be retrieved');
      return;
    }

    if (response.data.getSpectatorTicketsReport) {
      const {
        getSpectatorTicketsReport: { tickets, event, total_tickets },
      } = response.data;

      let _title = '',
        filename = '';
      if (event.isMultiDay) {
        _title =
          moment(event.start_date, 'MM-DD-YYYY').format('MMM DD - ') +
          moment(event.end_date, 'MM-DD-YYYY').format('DD - YYYY');
      } else {
        _title = moment(event.start_date, 'MM-DD-YYYY').format(
          'dddd MMM DD - YYYY'
        );
      }
      filename =
        'Ticket Holders List - ' +
        _title +
        ' - ' +
        event.track.name +
        ' - ' +
        event.name;

      const dateTimeStamp = moment().format('MMM DD - YYYY h:mm A');

      const csv = `Ticket Holders List
      Date, ${_title} 
      Event Name, ${event.name} 
      Track Name, ${event.track.name} 
      Tickets Sold(#), ${total_tickets}
      Exported:, ${dateTimeStamp} \n,,,,,,
    Purchaser's Name / Order #, Purchaser's Mobile Number, Purchaser's Email Address, Purchaser's Zip Code, Ticket, Ticket Number, Ticket Holder's Name, Ticket Price
    ${Object.values(tickets)
      .map(
        (value) =>
          `${
            value.user.formatted_name.replace(',', '') ?? ''
          },"${formatPhoneNumber(value.user.cellphone ?? '')}","${
            value.user.email ?? ''
          }","${value.user.zipcode ?? ''}","${value.ticket.name ?? ''}","${
            value.barcode ?? ''
          }","${value.holder ?? ''}","${value.price ?? ''}"`
      )
      .join('\n')}`;

      download(`${filename}.csv`, csv.trim());
    } else {
      toast.error('Ticket Holder List can not be retrieved');
    }
  } catch (e) {}
};

const exportLapReportCsv = async (lapReport, id, lapName) => {
  try {
    const response = await lapReport(id);
    if (!response || response.errors) {
      toast.error('Lap sponsorship report can not be retrieved');
      return;
    }

    if (response.data.getLapSponsorshipReport) {
      const {
        getLapSponsorshipReport: {
          name,
          event,
          payouts,
          data,
          laps_sold,
          num_laps,
        },
      } = response.data;

      let _title = '',
        filename = '';
      if (event.isMultiDay) {
        _title =
          moment(event.start_date, 'MM-DD-YYYY').format('MMM DD - ') +
          moment(event.end_date, 'MM-DD-YYYY').format('DD - YYYY');
      } else {
        _title = moment(event.start_date, 'MM-DD-YYYY').format(
          'dddd MMM DD - YYYY'
        );
      }
      filename =
        'Lap Sponsorship Report - ' +
        _title +
        ' - ' +
        event.track.name +
        ' - ' +
        lapName;

      const dateTimeStamp = moment().format('MMM DD - YYYY h:mm A');

      const csv = `Lap Sponsorship Report
      Date, ${_title} 
      Lap Sponsorship Name, ${name}
      # of Laps, ${num_laps}
      # of Laps Sold, ${laps_sold} 
      Track Name, ${event.track.name}
      ${Object.values(payouts)
        .map((payout) => `${payout.title} payout, ${payout.amount}   `)
        .join('\n')}
      Exported:, ${dateTimeStamp} \n,,,,,,
     Lap, Sponsored By, Lap Price, Purchaser's Name
    ${Object.values(data)
      .map(
        (value) =>
          `${value.name ?? ''},${value.lap_text ?? ''},${value.price ?? ''},${
            value.purchaser.replace(',', '') ?? ''
          }`
      )
      .join('\n')}`;

      download(`${filename}.csv`, csv.trim());
    } else {
      toast.error('Lap sponsorship report can not be retrieved');
    }
  } catch (e) {}
};

const Export = ({
  icon,
  button,
  date,
  disabled,
  text,
  lapSponsorshipData,
  ...props
}) => {
  const ticketReport = useGetSpectatorTicketsReport(date ?? null);
  const lapReport = useGetLapSponsorshipReport();

  const handleClick = (printOptionData) => {
    if (printOptionData) {
      if (printOptionData.action === 'getLapSponsorshipReport') {
        try {
          exportLapReportCsv(
            lapReport,
            printOptionData.id,
            printOptionData?.name?.includes('Print')
              ? printOptionData?.name.replace('Print', '')
              : printOptionData?.name
          );
        } catch (error) {
          logDevError(error);
          toast.error('Error exporting CSV');
        }
      } else {
        exportCsv(props, ticketReport);
      }
    } else {
      exportCsv(props, ticketReport);
    }
  };

  // This will generate the Lap Sponsorship report
  const generateLapSponsorshipReport = () =>
    lapSponsorshipData ? handleClick(lapSponsorshipData) : null;

  return (
    <div>
      {button ? (
        <div
          onClick={() => {
            if (lapSponsorshipData) {
              generateLapSponsorshipReport();
            } else {
              exportCsv(props, ticketReport);
            }
          }}
          role="presentation"
        >
          {button}
        </div>
      ) : (
        <Container
          onClick={
            disabled
              ? null
              : () => {
                  if (lapSponsorshipData) {
                    generateLapSponsorshipReport();
                  } else {
                    exportCsv(props, ticketReport);
                  }
                }
          }
          disabled={disabled}
        >
          <IconWrapper>
            {icon}
            <span>{text ? text : 'Export List'}</span>
          </IconWrapper>
        </Container>
      )}
    </div>
  );
};

export default Export;
