import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';
import { Checkbox } from 'components/Form/Checkbox';
import Spacer from 'components/Spacer';
import Text from 'components/Text';
import { useOfflineCheck } from 'hooks/useOfflineCheck';
import { PassRow } from './styles';

export const AssosiatedTickets = (props) => {
  const {
    currentUser,
    onChange,
    storedCheckins,
    storedUnChecks,
    setCurrentTicket,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { tickets } = currentUser ?? {
    tickets: [],
  };

  // const combined_tickets = [
  //   {
  //     ...currentUser.ticket,
  //     id: currentUser.id,
  //     barcode: currentUser.barcode,
  //     is_checked: currentUser.is_checked,
  //   },
  //   ...tickets,
  // ];

  const getDigits = (barcode) => {
    const digits = barcode.match(/\d+/g);
    return digits ? digits.join('') : '';
  };

  const getString = (barcode) => {
    const digits = barcode.match(/[a-zA-Z]+/g);
    return digits ? digits.join('') : '';
  };

  //   const number = parseInt(str.match(/\d+/)[0]);

  // // Extracting the text using regular expression
  // const text = str.match(/[A-Za-z]+/)[0];

  const isOffline = useOfflineCheck();

  return currentUser ? (
    <div style={{ marginBottom: 20 }}>
      <Spacer size={10} />
      {currentUser
        ? tickets.map((item) => {
            const { id, is_checked, name, barcode, is_refunded, is_credited } =
              item;
            const checked = isOffline
              ? // If it started out checked in AND isn't on the list
                // to be un-checked. OR if it started out un-checked
                // and isn't on the list to be checked.
                (is_checked && !storedUnChecks.includes(id)) ||
                (!is_checked && storedCheckins.includes(id))
              : is_checked;
            return (
              <PassRow key={id}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    inversed
                    name={`${item}_check_in`}
                    aria-checked={checked}
                    checked={checked || is_refunded}
                    refunded={is_refunded && !is_credited}
                    credited={!is_refunded && !!is_credited}
                    role="checkbox"
                    tabIndex={0}
                    onChange={() => {
                      if (!is_refunded) {
                        onChange(item);
                      }
                    }}
                    check={!is_refunded}
                    size={[36, 20, 3]}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: isMobile ? 'column' : 'row',
                      width: isMobile ? '100%' : '60%',
                      justifyContent: isMobile ? 'start' : 'space-between',
                    }}
                  >
                    <Text
                      inlineStyle={{
                        fontWeight: '600',
                        fontSize: 20,
                        marginLeft: 20,
                        color: '#fa4616',
                        cursor: 'pointer',
                      }}
                      onClick={() => setCurrentTicket(item.id)}
                    >
                      {name}
                    </Text>
                    {isMobile && <Spacer size={10} />}
                    <Text
                      inlineStyle={{
                        fontWeight: '600',
                        fontSize: 20,
                        marginLeft: 20,
                        justifyContent: isMobile ? 'start' : 'flex-end',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 10,
                      }}
                      color={theme.colors.text.gray}
                    >
                      <span> {`${getDigits(barcode)}  `} </span>
                      <span
                        style={{
                          fontSize: 22,
                        }}
                      >
                        {getString(barcode)}
                      </span>
                    </Text>
                    {isMobile && <Spacer size={10} />}
                  </div>
                </div>
              </PassRow>
            );
          })
        : null}
    </div>
  ) : null;
};
